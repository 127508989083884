import { Controller } from "@hotwired/stimulus";
import stickybits from "stickybits";

export default class extends Controller {
  static values = {
    parentClass: String,
    stickyClass: String,
    stuckClass: String,
    stickyChangeClass: String,
    verticalPosition: String,
    stickyBitStickyOffset: Number,
    noStyles: Boolean,
    useFixed: Boolean,
    useGetBoundingClientRect: Boolean,
  };

  connect() {
    this.initializeStickyBits();
  }

  initializeStickyBits() {
    const options = this.buildStickyOptions();
    this.sticky = stickybits(this.element, options);

    // Initial update to set the sticky state
    setTimeout(() => {
      this.sticky.update();
    }, 100);
  }

  buildStickyOptions() {
    return {
      verticalPosition: this.verticalPositionValue || "top",
      stickyBitStickyOffset: this.stickyBitStickyOffsetValue || 0,
      useStickyClasses: true,
      useGetBoundingClientRect: this.useGetBoundingClientRectValue || false,
      applyStyle: this.applyStickyStyle.bind(this),
    };
  }

  applyStickyStyle({}, instance) {
    if (instance.state === "sticky" || instance.state === "stuck") {
      this.addClasses(instance.el, this.stickyClassValue);
    } else {
      this.removeClasses(instance.el, this.stickyClassValue);
    }
  }

  addClasses(element, classes) {
    classes.split(" ").forEach((c) => {
      element.classList.add(c);
    });
  }

  removeClasses(element, classes) {
    classes.split(" ").forEach((c) => {
      element.classList.remove(c);
    });
  }

  disconnect() {
    if (!this.sticky) return;

    this.sticky.cleanup();
  }
}
