import { Controller } from "@hotwired/stimulus";

const DISABLED_CSS_CLASS = "hidden";
const next = "next";
const prev = "prev";

export default class extends Controller {
  static targets = ["list", "prevButton", "nextButton"];

  initialize() {
    this.anotherPrevIndex = 0;
    this.anotherNextIndex = 0;
    this.isNextButtonDisabled = false;
    this.isPrevButtonDisabled = true;
  }

  connect() {
    const fullWidth = this.listTarget.scrollWidth;
    const boxWidth = this.listTarget.clientWidth;

    if (fullWidth > boxWidth) {
      this.setBasicConfig();
      this.onScroll();
    }
  }

  onScroll() {
    this.listTarget.addEventListener(
      "scroll",
      this.debounce(() => {
        this.findElement(next);
        this.findElement(prev);
      }, 50)
    );
  }

  debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  setBasicConfig() {
    this.prevButtonTarget.classList.remove(DISABLED_CSS_CLASS);
    this.nextButtonTarget.classList.remove(DISABLED_CSS_CLASS);
    this.updateButton();
  }

  setPosition(direction, element) {
    let elementPosition;
    let listPosition;

    if (direction === next) {
      elementPosition = element.getBoundingClientRect().left;
      listPosition = this.listTarget.getBoundingClientRect().right;
    }

    if (direction === prev) {
      elementPosition = element.getBoundingClientRect().right;
      listPosition = this.listTarget.getBoundingClientRect().left;
    }

    return { elementPosition, listPosition };
  }

  setState(direction, index) {
    const elements = this.getItems();
    const maxIndex = elements.length - 1;
    const divider = 1.5;

    const calculatedWidthFromFirstElement =
      elements[0].getBoundingClientRect().width / divider;

    const calculatedWidthFromLastElement =
      elements[maxIndex].getBoundingClientRect().width / divider;

    if (direction === next) {
      this.isNextButtonDisabled = false;
      this.anotherNextIndex = index;
    }

    if (direction === prev) {
      this.isPrevButtonDisabled = false;
      this.anotherPrevIndex = index;
    }

    if (this.listTarget.scrollLeft < calculatedWidthFromFirstElement) {
      this.isPrevButtonDisabled = true;
    }

    const positionOfLastElement =
      this.listTarget.getBoundingClientRect().right -
      elements[maxIndex].getBoundingClientRect().left;

    if (positionOfLastElement > calculatedWidthFromLastElement) {
      this.isNextButtonDisabled = true;
    }
  }

  getItems() {
    const items = this.listTarget.children;
    const array = Object.values(items);

    return array;
  }

  scrollToElement(element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  }

  updateButton() {
    this.nextButtonTarget.disabled = this.isNextButtonDisabled;
    this.prevButtonTarget.disabled = this.isPrevButtonDisabled;
  }

  findElement(direction) {
    const elements = this.getItems();

    for (const [index, element] of elements.entries()) {
      const elementWidth = element.getBoundingClientRect().width;
      const divider = 1.5;

      const calculatedWidthFromElement = elementWidth / divider;

      const { elementPosition, listPosition } = this.setPosition(
        direction,
        element
      );

      const result = elementPosition - listPosition;

      if (result > -calculatedWidthFromElement) {
        this.setState(direction, index);

        this.updateButton();
        break;
      }
    }
  }

  scrollToPrev() {
    const elements = this.getItems();

    return this.scrollToElement(elements[this.anotherPrevIndex]);
  }

  scrollToNext() {
    const elements = this.getItems();

    if (this.anotherNextIndex === 0) {
      this.findElement(next);
    }

    return this.scrollToElement(elements[this.anotherNextIndex]);
  }
}
