import { Controller } from "@hotwired/stimulus";

const CONFIRM_MESSAGE = "Are you sure you want to discard your comment?";

export default class extends Controller {
  static targets = ["trixEditor", "wrapper", "submit"];

  connect() {
    if (this.wrapperTarget.dataset.expanded === "true") this.#focusBodyInput();

    this.handleTrixChange();
  }

  handleTrixChange() {
    const editorContent = this.trixEditorTarget.value;

    if (!editorContent) return (this.submitTarget.disabled = true);

    const plainTextContent = editorContent
      .replace(/<[^>]+>/g, "")
      .replace(/&nbsp;/g, " ");

    if (!this.submitTarget) return;

    this.submitTarget.disabled =
      plainTextContent === this.initialValue || !plainTextContent.trim();
  }

  collapseForm(event) {
    if (this.trixEditorTarget.value && !confirm(CONFIRM_MESSAGE)) {
      event.preventDefault();

      return false;
    }

    this.trixEditorTarget.form.reset();
    this.wrapperTarget.dataset.expanded = "false";

    if (this.element.dataset.mode === "edit") {
      const commentBody = this.element.previousElementSibling;

      if (!commentBody) return this.element.remove();

      commentBody.style.display = "block";
      this.element.remove();

      return;
    }

    if (!this.#hasComments()) this.#clearCommentSlot();
  }

  expandForm() {
    this.wrapperTarget.dataset.expanded = "true";
    this.#focusBodyInput();
  }

  submit() {
    this.trixEditorTarget.form.requestSubmit();
  }

  resetForm() {
    this.trixEditorTarget.form.reset();

    const formMode = this.element.dataset.mode;
    if (formMode === "edit") return;

    this.wrapperTarget.dataset.expanded = "false";
  }

  collapseFormOnError(event) {
    if (this.element.dataset.mode === "edit") return;
    if (event.detail.success) return;

    this.collapseForm(event);
  }

  #focusBodyInput() {
    setTimeout(() => this.trixEditorTarget.focus());
  }

  #hasComments() {
    return !!this.#getCommentList().querySelector("turbo-frame").children
      .length;
  }

  #getCommentList() {
    return this.#getCommentSlot().querySelector(".comment_list");
  }

  #getCommentSlot() {
    return this.element.closest(".comment-slot");
  }

  #clearCommentSlot() {
    this.#getCommentSlot().replaceChildren();
  }
}
