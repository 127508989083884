import { Diff2HtmlUI as OriginalDiff2HtmlUI } from "diff2html/lib/ui/js/diff2html-ui.js";
class Diff2HtmlUI extends OriginalDiff2HtmlUI {
  fileListToggle(startVisible) {
    const fileListWrapper = this.targetElement.querySelector(
      ".d2h-file-list-wrapper"
    );
    const fileList = this.targetElement.querySelector(".d2h-file-list");
    const diffWrapper = this.targetElement.querySelector(".d2h-wrapper");
    const toggleBtn = this.targetElement.querySelector(".d2h-file-list-header");

    if (fileList === null) return;

    const show = () => {
      toggleBtn.setAttribute("aria-expanded", "true");
      fileListWrapper.classList.add("expanded");
      fileList.style.display = "block";
      diffWrapper.classList.add("expanded");
    };

    const hide = () => {
      toggleBtn.setAttribute("aria-expanded", "false");
      fileListWrapper.classList.remove("expanded");
      fileListWrapper.style.height = "fit-content";
      fileList.style.display = "none";
      diffWrapper.classList.remove("expanded");
    };

    const toggle = () => {
      if (fileList.style.display === "none") {
        show();
      } else {
        hide();
      }
    };

    toggleBtn.addEventListener("click", () => toggle());

    const hashTag = this.getHashTag();
    if (hashTag === "files-summary-show") show();
    else if (hashTag === "files-summary-hide") hide();
    else if (startVisible) show();
    else hide();
  }
}

export default Diff2HtmlUI;
