import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["x"];

  connect() {
    if (this.hasXTarget) {
      const scrollX = this.xTarget.offsetLeft - this.element.clientWidth / 2;
      this.element.scrollTo(scrollX, 0);
    }
  }
}
