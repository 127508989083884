import { Controller } from "@hotwired/stimulus";

// * Connects to data-controller="resume-color-picker"
// * This controller is used to set color sets for resume

export default class extends Controller {
  static targets = ["colorField"];

  selectColorPair(event) {
    const mainColor = event.currentTarget.dataset.mainColor;
    const fontColor = event.currentTarget.dataset.fontColor;

    console.log(mainColor, fontColor);

    this.setColor(mainColor, "main_color");
    this.setColor(fontColor, "font_color");
  }

  setColor(color, field) {
    const colorField = this.colorFieldTargets.find((target) =>
      target.name.includes(field)
    );

    if (!colorField) return;

    colorField.value = color;
    colorField.dispatchEvent(new Event("change"));
  }
}
