import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";

// Connects to data-controller="acceptance_criteria"
export default class extends Controller {
  static targets = ["checkbox"];

  async changeAcceptanceCriteria() {
    const acceptanceCriteriumId = this.checkboxTarget.id;
    const membershipId = this.checkboxTarget.name;
    const url = `/students/memberships/${membershipId}/acceptance_criteria/${acceptanceCriteriumId}/check`;
    let method = "POST";
    if (!this.checkboxTarget.checked) {
      method = "DELETE";
    }
    const request = new FetchRequest(method, url);
    const response = await request.perform();
    if (!response.ok) {
      this.checkboxTarget.checked = !this.checkboxTarget.checked;
    }
  }
}
