import { Dropdown } from "tailwindcss-stimulus-components";

export default class extends Dropdown {
  toggle() {
    // Close other dropdowns before opening this one
    this.closeOtherDropdowns();
    super.toggle();
  }

  closeOtherDropdowns() {
    this.application.controllers.forEach((controller) => {
      if (controller.identifier === "dropdown") {
        if (controller === this) return;

        controller.openValue = false;
      }
    });
  }
}
