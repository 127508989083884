import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu"];

  toggle() {
    const menu = this.menuTarget;
    const currentExpanded = menu.getAttribute("aria-expanded");

    if (!currentExpanded) return;

    const expanded = currentExpanded === "true";

    menu.setAttribute("aria-expanded", expanded ? "false" : "true");
    this.toggleBodyOverflow();
  }

  toggleBodyOverflow() {
    document.body.classList.toggle("overflow-hidden");
  }
}
