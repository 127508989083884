import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  hideCommentSlot() {
    const commentSlot = this.getCommentSlot();
    const commentList = this.getCommentList(commentSlot);

    if (!this.isLastComment(commentList)) return;

    this.clearCommentSlot(commentSlot);
  }

  getCommentSlot() {
    return this.element.closest(".comment-slot");
  }

  getCommentList(commentSlot) {
    return commentSlot.querySelector(".comment_list");
  }

  isLastComment(commentList) {
    const comments = commentList.querySelector("turbo-frame").children;
    return comments.length === 1;
  }

  clearCommentSlot(commentSlot) {
    commentSlot.replaceChildren();
  }
}
