import { Controller } from "@hotwired/stimulus";

const UPDATE_INTERVAL = 10000; // update countdown every 10 seconds (we are counting minutes only)

export default class extends Controller {
  connect() {
    this.untilTime = new Date(this.element.dataset.untilTime);

    this.timer = setInterval(this.update.bind(this), UPDATE_INTERVAL);
    this.update();
  }

  disconnect() {
    clearInterval(this.timer);
  }

  update() {
    const differenceInMilliseconds = this.untilTime - new Date();

    const differenceInMinutes = differenceInMilliseconds / 1000 / 60;
    const differenceInHours = differenceInMinutes / 60;

    const restDifferenceInMinutes =
      differenceInHours * 60 - differenceInMinutes;

    const displayHours = Math.floor(differenceInHours);
    const displayMinutes =
      Math.floor(differenceInMinutes) - Math.abs(displayHours) * 60;

    this.element.textContent = `${displayHours > 0 ? displayHours : 0} h ${
      displayMinutes > 0 ? displayMinutes : 0
    } m left`;
  }
}
