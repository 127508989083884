import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="limit-business-context"
export default class extends Controller {
  static targets = ["buttonAdd", "form"];

  connect() {
    if (this.formTarget.querySelectorAll(".wrapper-div").length > 0) {
      this.buttonAddTarget.hidden = true;
    }
  }

  formTargetConnected() {
    this.buttonAddTarget.hidden = true;
  }

  formTargetDisconnected() {
    this.buttonAddTarget.hidden = false;
  }
}
