import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];

  toggle(event) {
    if (!this.hasContentTarget) {
      console.error("Accordion content target is missing");
      return;
    }

    const content = this.contentTarget;
    const currentState = event.newState;
    const isOpen = currentState === "open";

    if (isOpen) {
      this.expand(content);
    } else {
      this.shrink(content);
    }
  }

  shrink(content) {
    content.style.maxHeight = `${content.scrollHeight}px`;

    requestAnimationFrame(() => {
      content.style.transition = "max-height 0.3s ease-in-out";
      content.style.maxHeight = "0";
    });
  }

  expand(content) {
    const scrollHeight = content.scrollHeight;
    content.style.display = "block";

    requestAnimationFrame(() => {
      content.style.maxHeight = `${scrollHeight}px`;
    });

    content.addEventListener("transitionend", function handler() {
      content.style.maxHeight = "none";
      content.style.display = "";
      content.removeEventListener("transitionend", handler);
    });
  }
}
