import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    href: String,
  };

  openInNewTab() {
    window.open(this.hrefValue, "_blank").focus();
  }

  openInTheSameWindow() {
    window.open(this.hrefValue, "_self").focus();
  }
}
