import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  scrollToElement(event) {
    event.preventDefault();
    const target = event.target;
    const list = this.element.children[0];
    const targetElement = target.closest("li");

    const targetOffsetLeft = targetElement.offsetLeft;
    const listWidth = list.offsetWidth;
    const targetWidth = targetElement.offsetWidth;
    const targetCenterOffsetLeft =
      targetOffsetLeft - listWidth / 2 + targetWidth / 2;

    list.scrollTo({
      left: targetCenterOffsetLeft,
      behavior: "smooth",
    });
  }
}
