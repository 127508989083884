import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  initialize() {
    this.limit = Number(this.buttonTarget.dataset.limit);
  }

  connect() {
    const numberOfFields =
      this.element.querySelectorAll(".vanilla-item").length;

    if (numberOfFields === this.limit) {
      this.buttonTarget.disabled = true;
      this.buttonTarget.classList.add("hidden");
    }

    this.element.addEventListener("vanilla-nested:fields-added", (event) => {
      this.handleLimit(event);
    });

    this.element.addEventListener("vanilla-nested:limit-reached", (event) => {
      this.handleLimit(event);
    });
  }

  disconnect() {
    this.element.removeEventListener("vanilla-nested:fields-added", (event) => {
      this.handleLimit(event);
    });

    this.element.removeEventListener(
      "vanilla-nested:limit-reached",
      (event) => {
        this.handleLimit(event);
      }
    );
  }

  handleLimit(event) {
    const { target } = event;
    const numberOfFields = target.querySelectorAll(".vanilla-item").length;

    if (numberOfFields === this.limit) {
      this.buttonTarget.disabled = true;
      this.buttonTarget.classList.add("hidden");
    }

    if (numberOfFields > this.limit) {
      this.buttonTarget.disabled = true;

      const lastField = target.querySelector(".vanilla-item:last-child");
      const lastFieldInput = lastField.querySelector("input");

      lastFieldInput && lastFieldInput.remove();
      lastField && lastField.remove();
    }
  }
}
