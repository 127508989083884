import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field", "latitude", "longitude"];

  connect() {
    if (typeof google === "undefined") {
      console.error("Google Maps JavaScript API is not loaded");
      return;
    }

    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget, {
      types: ["(cities)"],
    });

    this.autocomplete.addListener(
      "place_changed",
      this.placeChanged.bind(this)
    );
  }

  disconnect() {
    if (!this.autocomplete) return;
    google.maps.event.clearInstanceListeners(this.autocomplete);
    const container = document.querySelector(".pac-container");
    if (container) container.remove();
  }

  placeChanged() {
    const place = this.autocomplete.getPlace();

    if (!place.geometry) {
      console.error("Place has no geometry");

      this.latitudeTarget.value = "";
      this.longitudeTarget.value = "";

      return;
    }

    this.latitudeTarget.value = place.geometry.location.lat();
    this.longitudeTarget.value = place.geometry.location.lng();
  }

  handleKeyDown(event) {
    if (event.key !== "Enter") return;

    // Prevent form submission when pressing Enter because the latitude and longitude fields are not filled yet
    event.preventDefault();
  }
}
