import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { FetchRequest } from "@rails/request.js";

// Connects to data-controller="drag"
export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this),
    });
  }

  end(event) {
    let projectid = event.item.dataset.projectid;
    let moduleid = event.item.dataset.moduleid;
    let taskid = event.item.dataset.taskid;
    let position = event.newIndex + 1;
    let url = this.data
      .get("url")
      .replace(":project_id", projectid)
      .replace(":module_id", moduleid)
      .replace(":task_id", taskid);

    const request = new FetchRequest("patch", url, {
      body: JSON.stringify({ position: position }),
    });

    request.perform();
  }
}
