import { Controller } from "@hotwired/stimulus";
import { createPopper } from "@popperjs/core";

// This controller handles the behavior of the tooltip component.
// It uses Popper.js to position the tooltip relative to the element that triggers it.
// It supports multiple tooltips on the same page by using different IDs for each tooltip.

export default class extends Controller {
  static values = {
    placement: String,
    id: String,
    offset: { type: Array, default: [0, 14] },
  };

  connect() {
    this.createPopperInstance();
  }

  disconnect() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
    }
  }

  createPopperInstance() {
    const { tooltip, element } = this.getElements();
    this.popperInstance = createPopper(element, tooltip, {
      placement: this.placementValue,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: this.offsetValue,
          },
        },
      ],
    });
  }

  getElements() {
    const tooltip = document.getElementById(this.idValue);
    const element = document.getElementById(`element_${this.idValue}`);
    return { tooltip, element };
  }

  show() {
    clearTimeout(this.tooltipTimeout);

    const { tooltip } = this.getElements();
    tooltip.style.display = "block";
    requestAnimationFrame(() => {
      tooltip.setAttribute("data-show", "");
    });

    this.popperInstance.update();
  }

  hide() {
    clearTimeout(this.tooltipTimeout);

    const { tooltip } = this.getElements();
    tooltip.removeAttribute("data-show");

    this.tooltipTimeout = setTimeout(() => {
      tooltip.style.display = "none";
    }, 300);
  }
}
