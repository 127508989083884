import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const frame = this.element.closest("turbo-frame");

    if (frame.src) {
      frame.reload();
    } else {
      frame.src = window.location.href;
    }

    this.element.remove();
  }
}
