import { Controller } from "@hotwired/stimulus";
import Typed from "typed.js";

// Connects to data-controller="animated-text"
export default class extends Controller {
  static targets = ["strike", "write"];

  static values = {
    write: String,
  };

  connect() {
    // Turn on the strike-through effect
    this.strikeTarget.classList.add("after:content-['']", "after:scale-x-100");

    const options = {
      strings: [this.writeValue],
      typeSpeed: 75,
      startDelay: 2000,
      showCursor: false,
    };

    this.type = new Typed(this.writeTarget, options);
  }

  disconnect() {
    if (!this.typed) return;

    this.typed.destroy();
  }
}
