import { Modal } from "tailwindcss-stimulus-components";
import { enter, leave } from "el-transition";

export default class extends Modal {
  static targets = ["container", "backdrop", "panel"];
  static values = {
    rerender: Boolean,
    closeOnSubmit: Boolean,
  };

  connect() {
    if (!this.rerenderValue) {
      enter(this.backdropTarget);
      enter(this.panelTarget);
    }

    this.lockScroll();
    super.connect();

    if (!this.closeOnSubmitValue) return;

    this.handleTurboSubmitEnd = this.close.bind(this);
    this.element.addEventListener(
      "turbo:submit-end",
      this.handleTurboSubmitEnd
    );
  }

  disconnect() {
    if (this.closeOnSubmitValue) {
      this.element.removeEventListener(
        "turbo:submit-end",
        this.handleTurboSubmitEnd
      );
    }
    super.disconnect();
  }

  close(e) {
    Promise.all([leave(this.backdropTarget), leave(this.panelTarget)]).then(
      () => {
        this.element.parentElement?.removeAttribute("src");
        this.element.remove();
      }
    );
    this.lockScroll();
    this.openValue = false;
  }

  lockScroll() {
    document.body.classList.toggle("overflow-hidden");
  }

  closeBackground(event) {
    if (event.target === this.containerTarget) this.close();
  }
}
