import { Autosave } from "tailwindcss-stimulus-components";

const SUCCESS_ICON = `<svg  xmlns="http://www.w3.org/2000/svg"  width="16"  height="16"  viewBox="0 0 24 24"  fill="currentColor"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" /></svg>`;

const ERROR_ICON = `<svg  xmlns="http://www.w3.org/2000/svg"  width="16"  height="16"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M12 9v4" /><path d="M12 16v.01" /></svg>`;

const SUBMITTING_ICON = `<svg  xmlns="http://www.w3.org/2000/svg"  width="16"  height="16"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="animate-spin"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 6l0 -3" /><path d="M16.25 7.75l2.15 -2.15" /><path d="M18 12l3 0" /><path d="M16.25 16.25l2.15 2.15" /><path d="M12 18l0 3" /><path d="M7.75 16.25l-2.15 2.15" /><path d="M6 12l-3 0" /><path d="M7.75 7.75l-2.15 -2.15" /></svg>`;

// * Autosave controller with focus restore
// * This controller extends the Autosave controller from tailwindcss-stimulus-components
export default class extends Autosave {
  static targets = ["form", "status"];

  static values = {
    restoreFocus: { type: Boolean, default: true },
  };

  connect() {
    super.connect();

    if (!this.restoreFocusValue) return;

    this.startObserving();
  }

  disconnect() {
    super.disconnect();

    if (!this.restoreFocusValue) return;

    this.stopObserving();
  }

  startObserving() {
    this.mutationObserver = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (!this.shouldRestoreFocus(mutation.target)) return;

        this.restoreFocus();
      }
    });

    this.mutationObserver.observe(this.element, {
      childList: true,
      subtree: true,
    });
  }

  stopObserving() {
    if (!this.mutationObserver) return;

    this.mutationObserver.disconnect();
  }

  shouldRestoreFocus(target) {
    return (
      target === this.element ||
      target.matches("turbo-frame") ||
      target.matches("div[id]")
    );
  }

  saveFocus() {
    this.lastFocusedElement = this.formTarget.querySelector(
      "input:focus, textarea:focus, select:focus"
    );
  }

  restoreFocus() {
    if (!this.lastFocusedElement) return;

    const { name } = this.lastFocusedElement;
    const element = this.element.querySelector(`[name='${name}']`);
    if (!element) return;

    if (element.type === "hidden") return;

    element.focus();

    // Here we skip the cursor position restoration for certain input types

    // Skip if the element is a masked input, as it will handle the cursor position
    if (element.getAttribute("data-controller") === "masked-input") return;

    if (element.type === "color") return;
    if (element.type === "email") return;
    if (element.type === "checkbox") return;
    if (element.type === "radio") return;
    if (element.tagName === "SELECT") return;

    // Restore the cursor position
    const value = this.lastFocusedElement.value;
    const length = value.length;
    element.setSelectionRange(length, length);
  }

  handleResponse(event) {
    const { status } = event.detail.fetchResponse.response;

    if (status === 200) {
      this.setStatus(this.successTextValue, SUCCESS_ICON);
    } else {
      this.setStatus(this.errorTextValue, ERROR_ICON);
    }
  }

  save() {
    clearTimeout(this.timeout);
    this.clearStyles();

    // Check if the status is already set to submitting
    const isSubmitting = this.statusTarget.textContent.includes(
      this.submittingTextValue
    );

    if (!isSubmitting) {
      this.statusTarget.innerHTML = `${SUBMITTING_ICON} ${this.submittingTextValue}`;
    }

    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit();
    }, this.submitDurationValue);
  }

  setStatus(message, icon = "") {
    this.clearStyles();

    this.statusTarget.innerHTML = `${icon} ${message}`;
    if (message === this.successTextValue) {
      this.statusTarget.classList.add("text-green-500");
    }

    if (message === this.errorTextValue) {
      this.statusTarget.classList.add("text-red-500");
    }

    this.timeout = setTimeout(() => {
      this.statusTarget.textContent = "";
      this.clearStyles();
    }, this.statusDurationValue);
  }

  clearStyles() {
    this.statusTarget.classList.remove("text-green-500");
    this.statusTarget.classList.remove("text-red-500");
  }
}
