import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["days", "hours", "minutes", "seconds"];

  connect() {
    this.updateCountdownDisplay(0, 0, 0, 0);
    this.startCountdown();
  }

  startCountdown() {
    const endDate = new Date(this.element.dataset.endDate);
    this.updateCountdown(endDate);

    const countdownInterval = setInterval(() => {
      const currentDate = new Date().getTime();
      const remainingTime = endDate - currentDate;

      if (remainingTime > 0) {
        this.updateCountdownTime(remainingTime);
      } else {
        this.stopCountdown(countdownInterval);
      }
    }, 1000);
  }

  updateCountdown(endDate) {
    const remainingTime = endDate - new Date().getTime();

    if (remainingTime > 0) {
      const { days, hours, minutes, seconds } =
        this.calculateTime(remainingTime);
      this.updateCountdownDisplay(days, hours, minutes, seconds);
    }
  }

  updateCountdownTime(remainingTime) {
    const { days, hours, minutes, seconds } = this.calculateTime(remainingTime);
    this.updateCountdownDisplay(days, hours, minutes, seconds);
  }

  calculateTime(remainingTime) {
    const SECOND_IN_MS = 1000;
    const MINUTE_IN_MS = 60 * SECOND_IN_MS;
    const HOUR_IN_MS = 60 * MINUTE_IN_MS;
    const DAY_IN_MS = 24 * HOUR_IN_MS;

    const days = Math.floor(remainingTime / DAY_IN_MS);
    remainingTime %= DAY_IN_MS;

    const hours = Math.floor(remainingTime / HOUR_IN_MS);
    remainingTime %= HOUR_IN_MS;

    const minutes = Math.floor(remainingTime / MINUTE_IN_MS);
    remainingTime %= MINUTE_IN_MS;

    const seconds = Math.floor(remainingTime / SECOND_IN_MS);

    return { days, hours, minutes, seconds };
  }

  updateCountdownDisplay(days, hours, minutes, seconds) {
    this.daysTarget.textContent = days;
    this.hoursTarget.textContent = hours;
    this.minutesTarget.textContent = minutes;
    this.secondsTarget.textContent = seconds;
  }

  stopCountdown(interval) {
    clearInterval(interval);
  }
}
