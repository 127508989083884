import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("change", this.saveDraft.bind(this));
  }

  approve() {
    const confirmed = confirm("Are you sure you want to submit your feedback?");
    if (!confirmed) return;

    this.element.querySelector('[name="approve"]').disabled = false;
  }

  saveDraft() {
    this.element.requestSubmit();
  }
}
